.container {
    display: grid;
    grid-template-columns: 1fr .6fr;
}

.contImage {
    position: relative;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 0.8900560053122812) 0%, rgba(255, 114, 43, 0.8508403190377713) 100%);
    height: 100%;
}

.contImage::after {
    content: '';
    contain: '';
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    opacity: .4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;

}

.contImage>img {
    height: 99vh;
    width: 100%;
    object-fit: cover;
    position: relative;
    z-index: 10;
}

.contText {
    width: 100%;
    height: 100vh;
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.contText h2 {
    font-size: 50px;
    margin-bottom: 70px;
}

.contText p {
    font-size: 22px;
    margin-bottom: 25px;
    padding: 0 30px;
}

@media (max-width:800px) and (min-width:500px) {
    .container {
        grid-template-columns: 1fr .9fr;
    }

    .contText h2 {
        font-size: 40px;
        margin-bottom: 70px;
    }

    .contText p {
        font-size: 16px;
        margin-bottom: 25px;
        padding: 0 30px;
    }
}

@media (max-width:499px) {
    .container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .contText {
        width: 100%;
        height: 100%;
        background: rgb(33, 33, 50);
        background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .contText h2 {
        font-size: 40px;
        margin-bottom: 70px;
    }

    .contText p {
        font-size: 16px;
        margin-bottom: 25px;
        padding: 0 30px;
    }

    .contImage>img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 10;
    }
    .contImage::after {

        width: 100%;
        height: 100%;  
    }
}