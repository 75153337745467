.container{
    display: grid;
    grid-template-columns: 1fr .65fr;
}

.contBotton{
    display: flex;
    justify-content: center;
    margin-top: 160px;
}

.contBotton > button {
    background: rgb(255,155,73);
    background: linear-gradient(90deg, rgba(255,155,73,1) 0%, rgba(255,114,43,1) 100%);
    outline: none;
    border: none;
    font-size: 40px;
    color: #fff;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 600;
    cursor: pointer;
}

.contImage{
    position: relative;
}

.contImage > div{
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255,155,73);
    background: linear-gradient(90deg, rgba(255,155,73,.5) 0%, rgba(255,114,43,.5) 100%);
    width: 100%;
    height: 100%;
}

.contProducts{
     padding: 90px 90px 30px 90px;
}

.contProducts >div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 90px;
}

.contProducts > div > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
    
}

.contProducts > div img{
    width: 300px;
    min-height: 170px;
}

.contProducts > div span{
    text-align: center;
    width: 60%;
}


@media (max-width:800px) and (min-width:601px) {
    .container{
        display: flex;
        flex-direction: column-reverse;
    }
    .contImage > img{
        width: 100vw;
        height: 700px;
    }
    
}

@media  (max-width:601px) {
    .container{
        display: flex;
        flex-direction: column-reverse;
    }
    .contImage > img{
        width: 100vw;
        height: 600px;
    }
    .contProducts >div{
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        justify-content: center;
        align-items: center;
    }
    .contProducts >div > div{
       margin-bottom: 60px;
    }
    .contBotton{
        margin-top: 70px;
    }
}