.card {
    position: relative;
    box-shadow: 4px 4px 8px rgba(0,0,0,.5);
    border-radius: 24px;
}

.cardTitle {
    background-color: #0d0b35;
    max-width: 350px;
    padding: 40px;
    padding-bottom: 60px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    color: #fff;
    min-height: 275px;
}

.cardTitle > h3 {
    margin-bottom: 15px;
}

.contCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 90px;
}

.container {
    padding: 30px 100px;
}

.contStart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 30px;
}

.contBton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.contBton>button {
    outline: none;
    border: none;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    padding: 10px 30px;
    font-size: 40px;
    border-radius: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
}

.contTitle {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contTitle>img {
    width: 100%;
}
.deco1{
    position: absolute;
    top: 50px;
    left: -70px;
}

.deco2{
    position: absolute;
    bottom: 60px;
    right: -70px;
    transform: rotate(180deg);
}
.deco3{
    position: absolute;
    top: 59.2%;
    left: 40%;
    width: 70px;
}

.decoTitle {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
}

.decoTitle1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, .3) 0%, rgba(255, 114, 43, .3) 100%);
    top: 0;
    left: 0;
}

.title {
    position: absolute;
    color: #fff;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title>span {
    display: inline-block;
    height: 4px;
    width: 83%;
    background-color: #ff722b;
    margin-top: 10px;
}

.title1 {
    position: absolute;
    color: #fff;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title1>span {
    display: inline-block;
    height: 4px;
    width: 83%;
    background-color: #ff722b;
    margin-top: 10px;
}

.contTitle>span {
    display: inline-block;
    height: 4px;
    background-color: #ff722b;
    width: 300px;
}

.list {
    position: relative;
}

.list h4 {
    font-size: 25px;
    color: #0d0b35;
    margin-bottom: 10px;
}

.list p {
    font-size: 24px;
    color: #706f6f;
    margin-bottom: 60px;
}

.list:before {
    content: '\2022';
    /* Utiliza un círculo como marcador */
    color: white;
    /* Color del texto en el círculo */
    background: #ff722b;
    /* Color del fondo del círculo (naranja) */
    border-radius: 50%;
    /* Hace que el círculo sea redondeado */
    width: 10px;
    /* Ancho del círculo */
    height: 10px;
    /* Altura del círculo */
    display: inline-block;
    /* Permite centrar verticalmente el círculo */
    margin-right: 5px;
    /* Espacio entre el círculo y el texto */
    position: absolute;
    top: 10px;
    left: -20px;
}

.list1 {
    position: relative;
}


.list1 p {
    font-size: 24px;
    color: #0d0b35;
    margin-bottom: 60px;
}

.list1:before {
    content: '\2022';
    /* Utiliza un círculo como marcador */
    color: white;
    /* Color del texto en el círculo */
    background: #ff722b;
    /* Color del fondo del círculo (naranja) */
    border-radius: 50%;
    /* Hace que el círculo sea redondeado */
    width: 10px;
    /* Ancho del círculo */
    height: 10px;
    /* Altura del círculo */
    display: inline-block;
    /* Permite centrar verticalmente el círculo */
    margin-right: 5px;
    /* Espacio entre el círculo y el texto */
    position: absolute;
    top: 10px;
    left: -20px;
}

@media (max-width:800px) and (min-width:601px) {

    .title {
        left: 13%;
        font-size: 25px;
    }
    .title1 {
        left: 29%;
        font-size: 27px;
    }
    
    .card {
        position: relative;
        box-shadow: 4px 4px 8px rgba(0,0,0,.5);
        border-radius: 24px;
        max-width: 350px;
        margin-bottom: 40px;
    }
    
    .contCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;
    }
}

@media (max-width:601px) {

    .title {
        left: 8%;
        font-size: 18px;
        text-align: center;
    }

    .title1 {
        left: 20%;
        font-size: 20px;
        text-align: center;
    }
    .card {
        position: relative;
        box-shadow: 4px 4px 8px rgba(0,0,0,.5);
        border-radius: 24px;
        max-width: 280px;
        margin-bottom: 40px;
    }
    
    .contCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;
    }
    .cardTitle {
        min-height: 330px;
    }
    .deco3{
        top: 65%;
        width: 60px;
    }
}