.container {
    display: grid;
    grid-template-columns: .65fr 1fr;
   
}
.conImage {
    position: relative;
}

.conImage>div {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
}

.imageReverse{
    height: 750px;
    width: 100%;
}

.contTitle {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin-top: 30px;

}

.contTitle>h1 {
    border-bottom: 2px solid rgb(255, 155, 73);
    padding: 0 60px;
}

.contProducs {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contProducs>div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 60px;
}

.contProducs>div>div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.contProducs>div img {
    width: 240px;
}

.contProducs>div span {
    color: #706f6f;
    font-weight: 500;
    margin-bottom: 15px;
}

.contProducs>div button {
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    color: #fff;
    outline: none;
    border: none;
    padding: 8px 35px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width:800px) and (min-width:601px) {
    .container {
        display: grid;
        grid-template-columns:  1fr;
       
    }
    .conImage {
        position: relative;
    }
    .conImage > img{
        width: 100%;
        max-height: 750px;
        
    }
    
    .conImage>div {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        width: 100vw;
        height: 100%;
    }
}

@media(max-width:601px) {
    .container {
        display: grid;
        grid-template-columns:  1fr;
       
    }
    .conImage {
        position: relative;
    }
    .conImage > img{
        width: 100%;
        max-height: 750px;
        
    }
    
    .conImage>div {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        width: 100vw;
        height: 100%;
    }
    .contProducs>div {
        display: flex;
        flex-direction: column;
     
    }
}