.container{
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    min-height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .2fr 1fr;
}

.contTitle{
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    padding-right: 50px;
    position: relative;
    z-index: 10;
}

.contTitle > div{
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 390px;
    font-size: 35px;
    border-radius: 30px;
    color: #fff;
}

.contPortfolio{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contPortfolio > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.contPortfolio > div  img{
    width: 80%;
    position: relative;
    z-index: 100;
}

.contPortfolio > div span{
   color: #fff;
   font-size: 55px;
}

.contTextFolio{
    margin-top: 40px;
}

.contPorfolioResidential{
    justify-content: flex-start;
}

.contPorfolioComercial{
    justify-content: flex-end;
    padding-bottom: 30px;
}

.decoText{
    height: 4px;
    width: 40%;
    background-color: #212132;
}

.decoImage{
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
    position: absolute;
    width: 70%;
    height: 50%;
    z-index: 10;
}

.deco1{
    top: -20px;
}

.deco2{
    top: 29%;
    right: 40px;
}

.decorador1{
    position: absolute;
    bottom: 0px;
    left: 40px;
    z-index: 1;
}

.decorador1 > img{
    position: absolute;
    bottom: 0px;
    left: 20px;
    z-index: 1;
    height: 300px;
}

.decorador2{
    position: absolute;
    top: 100px;
    right: 40px;
    z-index: 1;
}

@media (max-width:800px) and (min-width:0px) {
    .contPortfolio{
        display: grid;
        grid-template-columns: 1fr ;
    }

    .contPortfolio > div >img{
        width: 70%;
    }
    
    .contPortfolio > div span{
       font-size: 50px;
    }
    .contPorfolioResidential{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .contPorfolioComercial{
        justify-content: flex-end;
        padding-bottom: 30px;
    }
    .deco2{
        top: 24%;
        right: 40px;
    }
    
}

@media (max-width:499px) {
    .contPortfolio{
        display: grid;
        grid-template-columns: 1fr ;
    }

    .contPortfolio > div >img{
        width: 70%;
    }
    
    .contPortfolio > div span{
       font-size: 45px;
    }
    .contTitle{
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        padding-right: 50px;
        position: relative;
        z-index: 10;
    }
    
    .contTitle > div{
        height: 70px;
        width: 250px;
        font-size: 25px;
        border-radius: 25px;   
    }
    .deco2{
        top: 22%;
        right: 40px;
    }
    .decorador1{
        bottom: 0px;
        left: 20px;
    }
}