* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    list-style-type: none;
}

.buttonContact {
    border: none;
    border: 2px solid #ff9b49;
    height: 40px;
    background-color: transparent;
    outline: none;
    color: #ff722b;
    font-weight: 600;
    border-radius: 40px;
    padding: 0px 30px;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #a6a6af;
}

.container {
    display: grid;
    grid-template-columns: 1fr .2fr;
    width: 100vw;
    height: 90px;
    position: relative;
    z-index: 1000;
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
}



.contIcoHome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contIcoHome span {
    margin-right: 10px;
}

.contButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10000000;
}

.container ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 9%;

}

.container li {
    max-width: 170px;
    cursor: pointer;
    color: #a6a6af;
    text-align: center;
    font-weight: 700;
}

.container li>a:hover {
    color: #ff722b;
    font-size: 17px;
    transition: all .4s ease;
}

.containerFrequent {
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerFrequent:hover .contFrequentQuestions {
    display: flex;
}


.contFrequentQuestions {
    position: absolute;
    background-color: rgb(33, 33, 50);
    top: 90px;
    flex-direction: column;
    padding: 20px 40px;
    display: none;
}

.contFrequentQuestions>span {
    margin: 10px 0;
    cursor: pointer;
}

.contFrequentQuestions>span>a {
    color: #fff;
    font-weight: 600;
}

.contFrequentQuestions>span:hover a {
    color: #ff722b;
}

.contIcoHome span svg path {
    fill: #a6a6af;
    cursor: pointer;
}


.contIcoHome:hover svg>path {
    fill: #ff722b;
    transition: all .4s ease;
}

.contIcoHome:hover li {
    color: #ff722b;
    font-size: 17px;
    transition: all .4s ease;
}

.HamburgerMenu {
    display: none;
    opacity: 0;
    flex-direction: column;
    width: 34px;
    height: 30px;
    justify-content: space-between;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    z-index: 1000000000;
    left: 30px;
    top: 1cqmax;
}

.HamburgerMenu:hover {
    transform: scale(1.06);
}

.HamburgerMenu>div {
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 2px;
}


.HamburgerMenuDisabled>.firtLineMenu {
    position: absolute;
    top: 0;
    transform: rotate(0);
    animation: disabledHamburgerMenuFirstLine .6s ease;
}

.HamburgerMenuDisabled>.SecodLineMenu {
    position: absolute;
    top: 13px;
    transform: rotate(0);
    animation: disabledHamburgerMenuSecondLine .6s ease;
}

.HamburgerMenuDisabled>.thirthLineMenu {
    opacity: 1;
    position: absolute;
    top: 25px;
    animation: disabledHamburgerMenuthirthLine .6s ease;
}

.HamburgerMenuActive>.firtLineMenu {
    top: 15px;
    position: absolute;
    transform: rotate(45deg);
    animation: activeHamburgerMenuFirstLine .6s ease;
}

.HamburgerMenuActive>.SecodLineMenu {
    top: 15px;
    position: absolute;
    transform: rotate(-45deg);
    animation: activeHamburgerMenuSecondLine .6s ease;
}

.HamburgerMenuActive>.thirthLineMenu {
    position: absolute;
    top: 25px;
    opacity: 0;
    animation: activeHamburgerMenuthirthLine .6s ease;
}



@media (max-width:800px) and (min-width:0px) {

    .container {
        position: static;
        grid-template-columns: 1fr 1fr;
    }

    .containerFrequent {
        position: static;
        z-index: 0;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .containerFrequent > li {
        display: none;
    }
    
    .contFrequentQuestions {
        position: static;
        background-color: #ff722b;
        flex-direction: column;
        padding:0;
        display: flex;
    }

    .contFrequentQuestions>span {
        margin: 0 0 35px 0;
        cursor: pointer;
    }
    
    .contFrequentQuestions>span>a {
        color: #fff;
        font-weight: 700;
    }
    
    .contFrequentQuestions>span:hover a {
        color: rgb(33, 33, 50);
    }


    .contIcoHome svg {
        display: none;
    }

    .contIcoHome span {
        margin-right: 0;
    }

    .container ul {
        flex-direction: column;
        position: absolute;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #ff722b;
        color: #fff;
        height: 100vh;
        z-index: 10000000;
        width: 250px;
        padding-top: 80px;
        padding-left: 60px;
    }

    .container ul>svg {
        margin-bottom: 50px;
        width: 108px;
        height: 100px;
        position: relative;
        left: -15px;
    }

    .container li>a:hover {
        color: rgb(33, 33, 50);
        font-size: 17px;
        transition: all .4s ease;
    }

    .container ul li>a {
        color: #fff;
    }

    .container ul li {
        color: #fff;
        height: 60px;
        text-align: left;
    }

    .HamburgerMenu {
        opacity: 1;
        display: flex;
    }

    .MenuActive {
        left: 0;
        animation: activeMenu .6s ease;
    }

    .MenuDisabled {
        left: -250px;
        animation: disableMenu .6s ease;
    }

    @keyframes disableMenu {
        0% {
            left: 0px;
        }

        100% {
            left: -250px;
        }
    }

    @keyframes activeMenu {
        0% {
            left: -250px;
        }

        100% {
            left: 0px;
        }
    }
}



@keyframes disabledHamburgerMenuFirstLine {
    0% {
        top: 15px;
        transform: rotate(45deg);
    }

    100% {
        top: 0px;
        transform: rotate(0deg);
    }
}

@keyframes disabledHamburgerMenuSecondLine {
    0% {
        top: 15px;
        transform: rotate(-45deg);
    }

    100% {
        top: 13px;
        transform: rotate(0deg);
    }
}

@keyframes disabledHamburgerMenuthirthLine {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes activeHamburgerMenuFirstLine {
    0% {
        top: 0px;
        transform: rotate(0deg);
    }

    100% {
        top: 15px;
        transform: rotate(45deg);
    }
}

@keyframes activeHamburgerMenuSecondLine {
    0% {
        top: 13px;
        transform: rotate(0deg);
    }

    100% {
        top: 15px;
        transform: rotate(-45deg);
    }
}

@keyframes activeHamburgerMenuthirthLine {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}