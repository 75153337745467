.container{
    padding: 10px 50px;
}

.container h1{
    padding-left: 20px;
    margin-bottom: 60px;
    margin-top: 30px;
}

.galery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    margin-bottom: 80px;
}

.galery img {
    border-radius: 7px;
    margin-bottom: 12px;
}

.model{
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999999;
    position: fixed;
}

.model.open{
    visibility:  visible;
    opacity: 1;
    transform: scale(1);
}

.model.open svg{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 3rem;
    height: 3rem;
    padding: 5px;
    cursor: pointer;
}

.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.pics:hover{
    filter:brightness(50%);
    cursor: pointer;
}




@media (max-width:900px) {
    .container{
        padding: 10px 30px;
    }
    .galery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
        padding: 0 12px;
    }
    
}

@media (max-width:500px) {
    .container{
        padding: 10px 10px;
    }
    .galery {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        padding: 0 12px;
    }
}