.alert{
    background-color:#fff;
    border-radius:10px;
    max-width:80%;
    padding:35px
}

.btn{
    background-color:#6366F1;
    padding:8px 34px;
    color: #fff;
    outline: none;
    border: none;
    border-radius:5px;
    cursor: pointer;
}

.contAlert{
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:rgba(0,0,0,0.5);
    z-index:1000000000;
    display: flex;
    justify-content:center;
    align-items:center
}
.contBtn{
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top:20px
}

.contIcons{
    display: flex;
    justify-content: center;
    align-items:center;
    margin-bottom:0
}

.text{
  font-size:16px;
  margin-bottom:6px,
}

.textBtn{
    color:white;
    font-weight:bold;
    font-size:14px
}

.title{
    font-size:20px;
    font-weight:bold;
    text-align:center;
    margin-bottom:10px
}