.container {
    display: flex;
    width: 100vw;
    min-height: 450px;
    margin-top: 30px;
    background-color: #fff;
}



.contboton {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    position: relative;
    height: 110px;
}

.contboton>button {
    border: none;
    outline: none;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 55%);
    color: #fff;
    font-weight: 600;
    height: 50px;
    padding: 0 40px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    top: -40px;
}

.containerColor {
    background-color: #fff;
    flex-direction: row;
}

.containerColorIverted {
    flex-direction: row-reverse;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 55%);
}

.contText {
    width: 55%;
    padding: 40px;
}

.contText h2 {
    font-size: 32px;
    max-width: 85%;
}

.contText article {
    display: flex;

    min-height: 80%;
    justify-content: center;
    align-items: center;
}

.contText p {
    width: 85%;
    font-size: 20px;
}

.contenedorPrincipal {
    width: 45%;
    min-height: 320px;
    overflow: hidden;
    position: relative;
    z-index: 100;
    margin-top: 25px;
}

.contenedorSlideShow{
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1000;
}

.slide{
    min-width: 100%;
    overflow: hidden;
    min-height: 450px;
    max-height: 550px;
    position: relative;  
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide > img{
    height: 80%;
    width: 80%;
    vertical-align: top;
    object-fit: cover;
    position: relative;
    z-index: 100000;
}

.slide > video{
    height: 80%;
    width: 80%;
    vertical-align: top;
    object-fit: cover;
    position: relative;
    z-index: 100000;
}


.containerImage {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contImage img {
    height: 80%;
    width: 80%;
    position: relative;
    z-index: 100;
    object-fit: cover;
}

.decoImage {
    top: 0;
    left: 15%;
    position: absolute;
    width: 70%;
    height: 100%;
    /*background: rgb(33, 32, 47);
    background: linear-gradient(90deg, rgba(33, 32, 47, 1) 0%, rgba(21, 18, 32, 1) 55%);*/
    z-index: 10;
}

.decoTitle {
    height: 3px;
    width: 80%;
    margin: 10px 5px 10px 10px;
}

.footerServices {
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 55%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.footerServices>article {
    width: 70%;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 30px 0;
}

.footerServices>div {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.footerServices>div>button {
    border: none;
    outline: none;
    background: rgb(33, 32, 47);
    background: linear-gradient(90deg, rgba(33, 32, 47, 1) 0%, rgba(21, 18, 32, 1) 55%);
    color: #fff;
    height: 70px;
    padding: 0px 50px;
    border-radius: 26px;
    margin-bottom: 25px;
    font-size: 22px;
    cursor: pointer;
}

@media (max-width:800px) and (min-width:601px) {
    .decoImage {
        top: 2%;
        left: 3%;
        position: absolute;
        width: 70%;
        height: 80%;
        background: rgb(33, 32, 47);
        background: linear-gradient(90deg, rgba(33, 32, 47, 1) 0%, rgba(21, 18, 32, 1) 55%);
        z-index: 1;
    }
    
}

@media (max-width:600px) {
    .container {
        display: grid;
        width: 100vw;
        margin-top: 30px;
        background-color: #fff;
    }
    .contboton {
        margin-top: 30px;
     }
     
  
    .contText {
        width: 100%;
        padding: 20px;
        margin-bottom: 30px;
    }

    .contText h2 {
        font-size: 30px;
        max-width: 95%;
    }

    .contText article {
        display: flex;

        height: auto;
        justify-content: center;
        align-items: center;
    }

    .contText p {
        width: 90%;
        font-size: 18px;
    }

    .contImage {
        width: 100%;
        min-height: 320px;
        margin-bottom: 30px;
    }

    .contImage img {
        height: 80%;
        width: 80%;
    }
    .contenedorPrincipal {
        width: 100%;
        margin-bottom: 30px;
        margin-top: 45px;
    }

    .decoImage {
        top: 0;
        left: 0;

    }

    .footerServices>article {
        width: 80%;
        font-size: 18px;
        margin: 40px 0;
    }

    .footerServices>div {
        width: 80%;
    }

    .footerServices>div>button {

        height: 60px;
        padding: 0px 30px;
        border-radius: 18px;
        margin-bottom: 25px;
        font-size: 16px;
    }
}