.container {
    position: fixed;
    bottom: 0; 
    background-color: #fff;
    z-index: 10000000000000000;
    padding: 15px 45px;
    border-top-left-radius: 12px;
}

.active{
    right: 0;
    transition: all 700ms ease-in;
}

.disabled{
    right: -400px;
    transition: all 700ms ease-in;
}
.close{
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}



.contBtn > button{
    background-color: rgb(255, 155, 73);
    outline: none;
    border: none;
    cursor: pointer;
    height: 45px;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    margin-top: 15px;
}


.container>h3 {
    text-align: center;
    margin-bottom: 15px;
}

.container>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    position: relative;
}

.container>div input {
    height: 40px;
    width: 300px;
    margin-top: 6px;
    padding-left: 10px;
    border-radius: 6px;
    outline: none;
    border:none;
}

.container>div textarea {
    height: 120px;
    width: 300px;
    margin-top: 6px;
    resize: none;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 6px;
    outline: none;
    border:none;
    border: 2px solid #adadad
}

.contError{
    color: red;
    font-size: 11px;
    position: absolute;
    bottom: -15px;
}

@media (max-width:449px) {

    .container {
        position: fixed;
        bottom: 0; 
        background-color: #fff;
        z-index: 10000000000000000;
        padding: 15px 25px;
        border-top-left-radius: 12px;
    }
    .container>div input {
        height: 40px;
        width: 260px;
        margin-top: 6px;
        padding-left: 10px;
        border-radius: 6px;
        outline: none;
        border:none;
    }
    .container>div textarea {
        height: 80px;
        width: 260px;
        margin-top: 6px;
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
        border-radius: 6px;
        outline: none;
        border:none;
        border: 2px solid #adadad
    }

    .close{
        position: absolute;
        top: 20px;
        right: 5px;
        cursor: pointer;
    }
    
}