.container {
    width: 100vw;
    height: 100vh;
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
    display: grid;
    grid-template-rows: .15fr .2fr .56fr;
}

.contImage{
    background-color: rgba(255,155,73);
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
}

.contImage > img{
    width: 200px;
    height: 200px;
}

.conText{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 86%;
    margin-top: 15px;
    padding: 8px 35px;
    text-align: center;
    font-size: 12px;
    border-radius: 18px;
    font-weight: 700;
}

.contMaterial{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    z-index: 100;
}

.conTitle{
    margin-left: 150px;
    color: #fff;
    margin-top: 40px;
}

.deco1{
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
    position: absolute;
    top: 70px;
    left: 70vw;
    width: 320px;
    height: 320px;
}
.deco2{
    background: rgb(33, 33, 50);
    background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 500px;
    height: 500px;
}

.materialTop{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.materialBottom{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
}

@media  (max-width:601px) {
    .contMaterial{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        z-index: 100;
    }
    .container {
        width: 100vw;
        height: auto;
        background: rgb(33, 33, 50);
        background: linear-gradient(90deg, rgba(33, 33, 50, 1) 0%, rgba(21, 18, 32, 1) 100%);
        display: grid;
        grid-template-rows: .15fr .2fr .56fr;
    }
    .conTitle{
        margin-bottom:40px ;
        margin-left: 150px;
        color: #fff;
        margin-top: 40px;
        position: relative;
        z-index: 100;
    }
    .materialTop{
        margin-bottom: 80px;
    }
    
    .materialBottom{
        margin-bottom: 80px;
    }
}

