* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#videoid {
  background-color: #000;
}

.btnServicesHome {
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  height: 50px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-weight: 600;
  cursor: pointer;
}

.btnViewProHome {
  color: #ff722b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnViewProHome a {
  color: #ff722b;
}

.btnArrowHome {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cotnbtnsHome {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  justify-content: space-between;
  width: 55%;
}

.contHome {
  background: rgb(33, 33, 50);
  background: linear-gradient(
    90deg,
    rgba(33, 33, 50, 1) 0%,
    rgba(21, 18, 32, 1) 100%
  );
}

.contVideoHome {
  display: grid;
  grid-template-columns: 1fr 0.7fr;
}

.containerVideo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  position: relative;
}

.contVideoHome video {
  width: 90%;
  height: 400px;
  position: relative;
  z-index: 100;
}

.contTextVideo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contTextVideo > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contTextVideo div:first-child {
  background-color: #ff722b;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
}

.contTextVideo div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 35px;
  text-align: center;
  width: 70%;
  height: 300px;
}

.contCertificades {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-top: 60px;
  margin-bottom: 40px;
  position: relative;
  z-index: 100;
}

.contCertificades > div {
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  width: 150px;
  height: 150px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contCertificades > div > p {
  font-size: 36px;
  font-weight: 700;
}

.contCertificades > div > span {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.contCertificades div a {
  height: 100%;
  cursor: pointer;
}

.contCertificades div a img {
  height: 99%;
}

.contImagesHome {
  position: absolute;
  right: 120px;
  top: 15%;
}

.contImagesHome div:first-child {
  position: relative;
  top: 30px;
  left: -50px;
}

.contImagesHome div:last-child {
  position: absolute;
  bottom: -90px;
  left: -90px;
}

.conTitleHome {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  padding: 0px 80px;
  margin-top: 50px;
  color: #fff;
  width: 70%;
}

.contTextHome h1 {
  font-size: 60px;
  position: relative;
  z-index: 1000;
}

.containerWhatsApp {
  position: fixed;
  right: 30px;
  bottom: 40px;
  font-size: 90px;
  z-index: 9000000000000000000;
  fill: #fff;
  background-color: #075e54;
  width: 70px;
  height: 70px;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.containerWhatsApp a {
  
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.botonContact {
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  height: 50px;
  padding: 0px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 6px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.contInfoContact {
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px 15px 15px 0;
  border-radius: 2px;
}

.contInfoContact > p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.contRedes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 35px;
  padding-right: 50px;
}

.redes {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  padding-right: 35px;
}

.redes svg {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.contContacto {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.contTextHome h2 {
  color: #c6c6c6;
  font-size: 18px;
  margin-top: 60px;
  margin-bottom: 40px;
  width: 70%;
}

.contWeServices {
  background: rgb(33, 33, 50);
  background: linear-gradient(
    90deg,
    rgba(33, 33, 50, 1) 0%,
    rgba(21, 18, 32, 1) 100%
  );
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  padding-left: 50px;
}

.conTitleWeServices {
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  display: inline-block;
  width: 45%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 26px;
  font-weight: 300;
  border-radius: 2px;
}

.listServices {
  display: flex;
  flex-direction: row;
}

.listServices > div {
  margin: 20px 30px 50px 30px;
}

.listServices > div > ul {
  list-style-type: disc;
  color: #f0601d;
}

.listServices div > ul > li {
  color: #fff;
}

#decoHome {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  width: 550px;
  height: 250px;
  z-index: 1;
}

#decoHome2 {
  position: absolute;
  right: 90px;
  top: 110px;
}

#decoHome3 {
  position: absolute;
  right: 22%;
  bottom: 25px;
}

#decoHome4 {
  position: absolute;
  width: 450px;
  height: 450px;
  background-color: rgba(236, 236, 236, 0.034);
  right: 0;
  top: 0;
}

#decoHome5 {
  position: absolute;
  top: 5px;
  left: 0;
  background: rgb(255, 155, 73);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 73, 1) 0%,
    rgba(255, 114, 43, 1) 100%
  );
  width: 350px;
  height: 95%;
  z-index: 1;
}

#decoHome6 {
  position: absolute;
  right: 40px;
  top: 20%;
}

#decoHome7 {
  position: absolute;
  right: -50px;
  top: 30;
}

#decoHome7 img {
  height: 60px;
}

#decoHome8 {
  position: absolute;
  width: 450px;
  height: 140%;
  background-color: rgba(0, 0, 0, 0.134);
  left: 0;
  top: 80px;
}

#imagePortrait1 {
  height: 620px;
  position: relative;
  z-index: 100;
}

#imagePortrait2 {
  height: 300px;
  position: relative;
  z-index: 1000;
}

@media (max-width: 800px) and (min-width: 450px) {
  .contVideoHome {
    grid-template-columns: 1fr;
  }

  .contWeServices {
    grid-template-columns: 1fr;
    padding-left: 50px;
  }

  .conTitleHome {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0px 30px;
  }

  .contImagesHome {
    position: static;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
    justify-content: center;
  }

  .cotnbtnsHome {
    margin-top: 140px;
    width: 100%;
  }

  .contCertificades {
    width: 100%;
  }

  .contCertificades > div {
    width: 130px;
    height: 130px;
    border-radius: 8px;
  }

  #decoHome7 {
    position: absolute;
    right: 0px;
    top: 30;
  }

  #decoHome7 img {
    height: 60px;
  }

  #imagePortrait1 {
    height: 520px;
    position: relative;
    z-index: 100;
  }

  #imagePortrait2 {
    height: 250px;
    top: 70px;
    left: 130px;
  }
}

@media (max-width: 449px) {
  .botonContact {
    padding: 0px 40px;
    font-size: 12px;
    margin-right: 20px;
  }

  .contVideoHome {
    display: grid;
    grid-template-columns: 1fr;
  }

  .contWeServices {
    grid-template-columns: 1fr;
    padding-left: 30px;
  }

  .btnArrowHome > svg {
    height: 19px;
    width: 70px;
  }

  .btnViewProHome {
    font-size: 13px;
  }

  .btnServicesHome {
    height: 50px;
    width: 110px;
  }

  .btnServicesHome > span {
    font-size: 12px;
  }

  .cotnbtnsHome {
    margin-top: 0px;
    width: 100%;
  }

  .contCertificades {
    width: 100%;
  }

  .contCertificades > div {
    width: 90px;
    height: 90px;
    border-radius: 8px;
  }

  .contCertificades > div > p {
    font-size: 20px;
    font-weight: 700;
  }

  .contTextVideo div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 25px;
    text-align: center;
    width: 100%;
    height: 200px;
  }

  .conTitleWeServices {
    width: 60%;
  }

  .contCertificades > div > span {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  .conTitleHome {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0px 25px;
  }

  .contInfoContact > p {
    font-size: 13px;
  }

  .contRedes {
    padding-right: 0;
  }

  .contImagesHome {
    position: static;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    display: grid;
    justify-content: center;
  }

  .contImagesHome div:first-child {
    position: relative;
    z-index: 100;
    left: -3%;
  }

  .contImagesHome div:last-child {
    position: relative;
    z-index: 100;
    left: -40px;
    top: -50px;
  }

  .contTextHome {
    width: 100%;
  }

  .contTextHome h1 {
    font-size: 35px;
    position: static;
    z-index: 1000;
  }

  .contTextHome h2 {
    color: #c6c6c6;
    font-size: 18px;
    margin-top: 60px;
    width: 100%;
    position: relative;
    z-index: 10;
  }

  #decoHome {
    position: absolute;
    bottom: -60px;
    right: 0;
    background: rgb(255, 155, 73);
    background: linear-gradient(
      90deg,
      rgba(255, 155, 73, 1) 0%,
      rgba(255, 114, 43, 1) 100%
    );
    width: 550px;
    height: 250px;
    z-index: 1;
  }

  #decoHome3 > img {
    width: 100vw;
  }

  #decoHome4 {
    width: 250px;
    height: 250px;
  }

  #decoHome5 {
    position: absolute;
    top: 90px;
    left: 0;
    background: rgb(255, 155, 73);
    background: linear-gradient(
      90deg,
      rgba(255, 155, 73, 1) 0%,
      rgba(255, 114, 43, 1) 100%
    );
    width: 200px;
    height: 70%;
    z-index: 1;
  }

  #decoHome7 {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  #decoHome7 img {
    height: 40px;
    width: 200px;
  }

  #decoHome8 {
    position: absolute;
    width: 250px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.134);
    left: 0;
    top: 0px;
  }

  #imagePortrait1 {
    height: 320px;
  }

  #imagePortrait2 {
    height: 120px;
  }
}

@media (max-height: 770px) {
  #decoHome {
    bottom: -100px;
  }
}
