.container {
    display: grid;
    grid-template-columns: 1fr .65fr;
}
.containerReverse {
    display: grid;
    grid-template-columns: .65fr 1fr;
    margin-top: 40px;
}

.contBton{
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.contBton > button{
    background: rgb(255,155,73);
    background: linear-gradient(90deg, rgba(255,155,73,1) 0%, rgba(255,114,43,1) 100%);
    border: none;
    outline: none;
    font-size: 40px;
    padding: 10px 30px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    font-weight: 500;
}

.contInfo {
    padding: 60px 60px 30px 60px;
    box-sizing: border-box;
}
.contElements{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.contElements > div{
    display: flex;
    flex-direction:row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    
}
.contProducs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contactUs{
    margin-top: 50px;
    margin-left: 20px;
    color: #0d0b35;
    font-weight: 700;
}

.contProducs > img{
   width: 230px;
 }

.contProducs > span{
   margin-top: 15px;
   color: #706f6f;
   font-weight: 700;
}

.conImage {
    position: relative;
}

.conImage>div {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
}

.contMaterials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.contMaterials>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80%;
}

.contMaterials>div>span {
    font-size: 20px;
}

.conTitle {
    color: #0d0b35;
    display: inline-block;
    padding-left: 30px;
    padding-right: 60px;
    border-bottom: 2px solid #ff9b49;
    font-size: 35px;
}

.image {
    width: 100%;
    height: 100%;
    background-size: contain;
}
.contOneProduct{
    margin-top: 80px;
}

@media (max-width:800px) and (min-width:601px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
    }
    .containerReverse {
        display: flex;
        flex-direction: column;
    }

    .conImage>div {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        width: 100%;
        min-height: 450px;
        height: 30vh;
    }

    .image {
        width: 100%;
        min-height: 450px;
        height: 30vh;
        background-size: contain;
    }
    .contMaterials>div {
        margin-bottom: 50px;
    }
}

@media  (max-width:601px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
    }
    .containerReverse {
        display: flex;
        flex-direction: column;
    }

    .conImage>div {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        width: 100%;
        min-height: 450px;
        height: 30vh;
    }
    .contElements > div{
        display: flex;
        flex-direction:column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        
    }
    .contProducs{
        margin-top: 50px;
    }

    .image {
        width: 100%;
        min-height: 450px;
        height: 30vh;
        background-size: contain;
    }
    .contMaterials {
        grid-template-columns: 1fr ;
        height: 100%;
        padding-top: 30px;
    }

    .contMaterials>div {
        margin-bottom: 50px;
    }
    .contOneProduct{
        margin-top: 0;
    }
    
}