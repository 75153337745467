.container {
    display: grid;
    grid-template-columns: .8fr 1fr;
}

.contBton>button {
    outline: none;
    border: none;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    padding: 10px 30px;
    font-size: 40px;
    border-radius: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
}

.contPergola {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
}

.contPergola>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contPergola>span {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #706f6f;
    font-size: 20px;
    font-weight: 600;
    margin: 35px 0;
}

.conImage {
    position: relative;
}

.conImage>div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ff55006c;
}

.conImage>img {
    height: 100vh;
    min-height: 650px;
    width: 100%;
}

.conText {
    margin-top: 70px;
    color: #0d0b35;
    font-weight: 800;
    font-size: 20px;
}

@media (max-width:800px) and (min-width:601px) {
    .container {
        grid-template-columns: 1fr;
    }

    .conImage>img {
        height: 50vh;
        min-height: 650px;
        width: 100%;
    }
}

@media (max-width:601px) {
    .container {
        grid-template-columns: 1fr;
    }

    .conImage>img {
        height: 50vh;
        min-height: 650px;
        width: 100%;
    }
    .conText {
        font-size: 14px;
    }
}