.container {
    display: grid;
    grid-template-columns: .65fr 1fr;
}

.containerReverse {
    display: grid;
    grid-template-columns: 1fr .65fr;
    margin-top: 60px;
}


.contBotton {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
}

.contBotton>button {
    outline: none;
    border: none;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    padding: 10px 30px;
    font-size: 40px;
    border-radius: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
}

.contBottonCenter {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.contBottonCenter>button {
    outline: none;
    border: none;
    background: rgb(255, 155, 73);
    background: linear-gradient(90deg, rgba(255, 155, 73, 1) 0%, rgba(255, 114, 43, 1) 100%);
    padding: 10px 30px;
    font-size: 40px;
    border-radius: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #fff;
}

.contProducst {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
}

.contProducst>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 70px;
}

.contProducst>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.contProducst>div img {
    width: 200px;
}

.contProducst>div span {
    width: 50%;
    text-align: center;
    color: #706f6f;
    font-weight: 600;
}

#contlastElement{
    align-items: flex-start
}

#lastElement{
    align-items: flex-start 
}

@media (max-width:800px) and (min-width:601px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
    
    } 
    .containerReverse {
        display: flex;
        flex-direction: column-reverse; 
    } 
}

@media (max-width:601px) {
    .container {
        display: grid;
        grid-template-columns: 1fr;
    
    } 
    .containerReverse {
        display: flex;
        flex-direction: column-reverse;
    
    }  
    .contProducst>div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0px;
    }
    .contProducst>div>div {
        margin-bottom: 60px;
    
    }
    #contlastElement{
        align-items: center
    }
    
    #lastElement{
        align-items: center
    }
}